<template>
    <div  class="price-offer":class="[this.$store.state.VerfyLogin ? `open` : ``]">
        <div class="overlay" @click="this.$store.state.VerfyLogin = false"></div>
        <div class="card-package">
            <div class="col-12">
               <img src="../../../assets/img/blogs/modal.png" alt="modal">
            </div>
            <div class="col-12">
              <h3 class="mt-4 text-center" style="font-size: 16px;font-weight: 500;margin-bottom: 12px;">
            {{
                this.$i18n.locale == 'ar' ?
                    `شكرا لاختياركم اتمام....`
                    :
                    `Thank you for choosing us!`
            }}
        </h3>
                <h2 class="title text-center main-title">{{ this.$i18n.locale == `ar` ? `قم بتسجيل الدخول للمتابعة !` : `login To continue` }}</h2>
                <p class=" text-center">{{ this.$i18n.locale == `ar` ? `قم بتسجيل الدخول لاكتشاف عالم جديد من الخدمات التي تناسب احتياجاتك واحتياجات شركتك !` : `Log in to discover a new world of services that suit your needs and the needs of your company!` }}</p>
            </div>
            <div class="mt-4 d-flex justify-content-center sub-title">
                <!-- <router-link style="margin-right: 15px;" to="/register" class="next-step signup-btn">
                    {{ this.$i18n.locale == `ar` ? `إنشاء حساب جديد` : `Register` }}
                </router-link> -->
                <button  style="margin-right: 15px;" @click="GoRegister" class="next-step signup-btn" >
                  {{ this.$i18n.locale == `ar` ? `إنشاء حساب جديد` : `Register` }}
                </button>

            <!-- <router-link style="margin-right: 15px;" class="next-step login-btn" to="/login">
                {{ this.$i18n.locale == `ar` ? `تسجيل الدخول` : `Login` }}
            </router-link> -->
            <button  style="margin-right: 15px;" @click="GoLogin" class="next-step login-btn" >
              {{ this.$i18n.locale == `ar` ? `تسجيل الدخول` : `Login` }}
             </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Blogs Details',
    data() {},
    methods:{
    CloseModal(){
        this.$store.state.VerfyLogin = false
    },

    GoRegister(){
      this.$store.state.VerfyLogin = false
      window.location.href = '/register?VerfyLogin=true'

    },
    GoLogin(){
      this.$store.state.VerfyLogin = false
      window.location.href = '/login?VerfyLogin=true'

    }
}
}


</script>
<style scoped>
  .overlay {
    pointer-events: none;
  }

  .card-package {
    pointer-events: auto;
  }

</style>
